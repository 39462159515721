import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    debug: false,
    backend: { queryStringParams: { v: "bd42681a" } },
    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // react-i18next options
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
