import React, { useEffect, useState } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import styles from "./Layout.module.css";
import { useSelector, useDispatch } from "react-redux";
import { customerActions } from "@_actions/customer.actions";
import Error from "@_pages/Error/Error";
import defaultFavicon from "@_assets/icons/favicon.ico";
import i18n from "@_core/i18n.js";
import { GetCaseId } from "@_utils/helpers.js";
import { useHistory } from "react-router-dom";

function Layout(props) {
  const dispatch = useDispatch();
  const customerDetails = useSelector((state) => state.customerReducer);

  const [customerFavicon, setCustomerFavicon] = useState("");
  const [customerError, setCustomerError] = useState(false);

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          dispatch(customerActions.customer(window.location.hostname));
          // Handle back event
        }
      }
    });
  }, [locationKeys, history, dispatch]);

  useEffect(() => {
    const getConfig = async () => {
      const caseId = GetCaseId();
      const searchParams = new URLSearchParams(window.location.search);
      const userId = searchParams.get("user_id");
      const browserlanguage = i18n.language && i18n.language.split("-")[0];
      dispatch(
        customerActions.customer(
          window.location.hostname,
          browserlanguage,
          caseId,
          userId
        )
      );
    };
    getConfig();
  }, [dispatch]);

  useEffect(() => {
    if (customerDetails.customer.favicon_url) {
      setCustomerFavicon(customerDetails.customer.favicon_url);
    } else {
      setCustomerFavicon(defaultFavicon);
    }
  }, [customerDetails.customer.favicon_url]);

  useEffect(() => {
    if (
      parseInt(customerDetails.message.error_code) === 400 ||
      parseInt(customerDetails.message.error_code) === 404 ||
      parseInt(customerDetails.message.error_code) === 500
    ) {
      setCustomerError(true);
    }
  }, [customerDetails.message.error_code]);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (customerDetails.customer.favicon_url) {
      favicon.href = customerFavicon;
    }
  }, [customerDetails.customer.favicon_url, customerFavicon]);

  return (
    <>
      {!customerError ? (
        <div className={styles.layout_container}>
          <div>
            <Header />
            {window.location.pathname !== "/login" ? (
              <div className={styles.content}>
                <div>{props.children}</div>
              </div>
            ) : (
              <div className={styles.login_content}>{props.children}</div>
            )}
          </div>
          <Footer />
        </div>
      ) : (
        <div>
          <Error />
        </div>
      )}
    </>
  );
}

export default Layout;
