import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./AboutUs.module.css";
import BackBtn from "@_components/BackBtn/BackBtn";
import { useSelector } from "react-redux";

function AboutUs() {
  const { t } = useTranslation();

  const customerDetails = useSelector((state) => state.customerReducer);

  return (
    <div className={styles.about_us_container}>
      <div className={styles.about_us_text}>
        <BackBtn />
        <div className={styles.logo_container}>
          <h2>{t("_about_us")}</h2>
        </div>
        <div>
          {customerDetails.customer.about_us_text?.split("\n").map((x, y) => (
            <p key={y}>{x}</p>
          ))}
        </div>
      </div>
      <div className={styles.company_picture_container}>
        {customerDetails.customer.about_us_image_url !== null ? (
          <div>
            <img
              src={customerDetails.customer.about_us_image_url}
              alt="Company"
              style={{ marginTop: "80px" }}
              className={styles.company_picture}
            />
          </div>
        ) : (
          <>
            {" "}
            <div>
              <img
                src="/assets/default_about_us.jpg"
                alt="Company"
                style={{ marginTop: "80px" }}
                className={styles.company_picture}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AboutUs;
